import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/SEO"

const NieuwbouwPage = () => {
  const data = useStaticQuery(graphql`
    query {
      main: file(relativePath: { eq: "images/nieuwbouw/hero.jpg" }) {
        ...heroImage
      }
      nieuwbouw: file(
        relativePath: { eq: "images/nieuwbouw/services/nieuwbouw.jpg" }
      ) {
        ...columnImage
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Nieuwbouw - Uytterhaegen Jan BV" pathname="/nieuwbouw" />
      <BackgroundImage
        Tag="section"
        className="main-hero"
        fluid={data.main.childImageSharp.fluid}
        backgroundColor={`#040e18`}
      >
        <div className="main-hero-content">
          <div className="main-hero-content-title">
            <h2>Nieuwbouw</h2>
          </div>
        </div>
      </BackgroundImage>
      <div className="container">
        <div className="wrapper">
          <div className="content">
            <section>
              <div className="two-column">
                <div className="column-60">
                  <h1>Nieuwbouw</h1>
                  <p>
                    Wij zijn de expert in de totale installatie van centrale
                    verwarming en sanitair. Van de traditionele technieken tot
                    de allernieuwste toepassingen.
                  </p>
                  <p>
                    Wij zoeken samen met u naar de{" "}
                    <strong>beste oplossing op uw maat</strong>. U kan rekenen
                    op <strong>professioneel</strong> advies zodat u goed weet
                    wat u mag verwachten.
                  </p>
                  <p>
                    Van een eenvoudige condensatieketel met vloerverwarming of
                    radiatoren, tot een warmtepomp of een hybride, er zijn
                    zoveel mogelijkheden afhankelijk van de situatie en uw
                    budget.
                  </p>
                </div>
                <div className="column-40">
                  <Img
                    alt={data.nieuwbouw.childImageSharp.name}
                    fluid={data.nieuwbouw.childImageSharp.fluid}
                  />
                </div>
              </div>
            </section>
            {/* <section>
              <h1>Enkele van onze realisaties</h1>
              <Lightbox
                thumbs={data.thumbs.edges}
                images={data.realisaties.edges}
              />
            </section> */}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NieuwbouwPage
